body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Atkinson Hyperlegible', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.QuillEditor {
  margin: 0 16px;
}


.QuillEditor .ql-container.ql-snow {
  border-color: transparent;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.ql-snow .ql-editor h1 {
  font-size: 24px;
  font-weight: bold;
}

.ql-snow .ql-editor h2 {
  font-size: 18px;
  font-weight: bold;
}

.QuillEditor .ql-toolbar.ql-snow {
  border-color: transparent;
}

.QuillEditor .ql-editor.ql-blank::before {
  font-style: normal;
  color: #BFBFBF;
}